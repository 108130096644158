export class Montage {
    entfernung?: number;
    fahrzeugezahl?: number;
    halbtagspauschale?: number;
    inland?: boolean;
    mitarbeiterzahl?: number;
    reisekostenpauschale?: number;
    sumMontage?: number;
    tagespauschale?: number;
    uebernachtung?: number;
    ueberstunden?: number;

    constructor(montageData?: any) {
        Object.assign(this, montageData);
    }
}
