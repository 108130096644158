import {Montage} from "@/models/Montage";
import {Material} from "@/models/Material";

export class Offer {
    angebotsNr?: string;
    ihreAnfrage?: string;
    contactPerson?: string;
    zahlungsbedingungen?: string;
    anrede?: string;
    lieferzeitMaterial?: string;
    lieferzeitMontage?: string;
    lieferbedingungen?: string;
    gewaehrleistungen?: string;
    technischeLieferbedingungen?: string;
    dokumentationen?: string;
    angebotsgueltigkeit?: string;
    bemerkungMaterial?: string;
    bemerkungMontage?: string;
    bemerkungDokumentation?: string;
    montage?: Montage;
    material?: Material;
    documentationEntries?: any[];
    documentationTableExist?: boolean;

    constructor(offerData: any) {
        Object.assign(this, offerData);
    }
}
