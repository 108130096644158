import {OfferMachine} from "@/models/OfferMachine";

export class Material {
    offerMachines?: OfferMachine[];
    angebotswertMaterial?: number

    constructor(materialData?: any) {
        Object.assign(this, materialData);
    }
}
