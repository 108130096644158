


























































































































































































































































































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Machine} from "@/models/machine";
import {Station} from "@/models/station";
import User from "@/models/user";
import {Customer} from "@/models/customer";
import StationRepository from "@/api/StationRepository";
import {RepositoryFactory} from "@/api/RepositoryFactory";
import PdfService from "@/views/offer/pdf.service";
import WordService from "@/views/offer/word.service";
import {OfferMachine} from "@/models/OfferMachine";
import {Offer} from "@/models/Offer";
import {Montage} from "@/models/Montage";
import {Material} from "@/models/Material";

const stationRepository: StationRepository = RepositoryFactory.get('station');

const CustomerStore = namespace('customer');
const StationStore = namespace('station');
const UserStore = namespace('user');

var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  components: {
    MaterialTableComponent: () => import('./MaterialTable.component.vue'),
    MontageTableComponent: () => import('./MontageTable.component.vue'),
    DokumentationTableComponent: () => import('./DokumentationTable.component.vue'),
    ConfirmDialogComponent: () => import('@/components/dialog/ConfirmDialog.component.vue')
  }
})
export default class OfferView extends Vue {

  @CustomerStore.Getter('selectedCustomer')
  public customer!: Customer;

  @StationStore.Getter('selectedStation')
  public station!: Station;

  @UserStore.Getter('users')
  public loggedInUser!: User;

  @Prop([])
  public machines!: Machine[];

  public offerMachines!: OfferMachine[];

  public documentationEntries!: any[];

  public documentationEntriesChanged(value: any) {
    this.offer.documentationEntries = value;
  }

  public customerName_form = ''
  public street_form = ''
  public houseNo_form = ''
  public zipCode_form = ''
  public city_form = ''
  public country_form = ''
  public contactPerson_form = ''
  public mail_form = ''

  public fab = false;
  public transition = 'slide-y-reverse-transition';

  public documentationIsShown: boolean = false;

  public changeDocumentationIsShown() {
    this.offer.documentationTableExist = this.documentationIsShown;
  }

  private offer: Offer = {};

  public montage: Montage = {};
  public material: Material = {};

  public myOffer = {
  }

  async initializeOffer() {
    this.montage = new Montage({})
    this.material = new Material({})
    this.myOffer = {
      angebotsNr : this.angebotsNr,
      ihreAnfrage : this.ihreAnfrage,
      contactPerson : '',
      zahlungsbedingungen : this.zahlungsbedingungen[0],
      //angebotswertMaterial : 0,
      anrede: this.anrede,
      lieferzeitMaterial: this.lieferzeitMaterial[0],
      lieferzeitMontage: this.lieferzeitMontage[0],
      lieferbedingungen: this.lieferbedingungen[0],
      gewaehrleistungen: this.gewaehrleistungen[0],
      technischeLieferbedingungen: this.technischeLieferbedingungen[0],
      dokumentationen: this.dokumentationen[0],
      angebotsgueltigkeit: this.angebotsgueltigkeit[0],
      bemerkungMaterial: this.bemerkungMaterial,
      bemerkungMontage: this.bemerkungMontage,
      bemerkungDokumentation: this.bemerkungDokumentation,
      montage: this.montage,
      material: this.material,
      documentationEntries: [],
      documentationTableExist: this.documentationIsShown
    }

    this.offer = new Offer(this.myOffer);
  }

  async created() {
    await this.initializeOffer();
    this.customerName_form = this.customer.name;
    this.street_form = this.customer.street;
    this.houseNo_form = this.customer.houseNo;
    this.zipCode_form = this.customer.zipCode;
    this.city_form = this.customer.city;
    this.country_form = this.customer.country;
    this.contactPerson_form = this.station.contactPerson;
    this.mail_form = this.station.mail;
    this.offer.contactPerson = this.station.contactPerson;
    this.selectedUser = this.user[0];
  }

  public selectedUser = {
    id: 0,
    name: '',
    nameShort: '',
    email: '',
    tel: '',
    fax: '',
    cc: '',
    title: ''
  };

  public user = [
      {
        id: 1,
        name: 'Dennis Lange',
        nameShort: 'D. Lange',
        email: 'd.lange@fahlke.de',
        tel: '+ 49-4261-9143-25',
        fax: '+ 49-4261-9143-143',
        cc: 'csg@fahlke.de',
        title: 'Production Manager'
      },
      {
        id: 2,
        name: 'Yvan Pacheco',
        nameShort: 'Y. Pacheco',
        email: 'y.pacheco@fahlke.de',
        tel: '+ 49-4261-9143-142',
        fax: '+ 49-4261-9143-143',
        cc: 'csg@fahlke.de',
        title: 'Project Manager'
      },
      {
        id: 3,
        name: 'New User',
        nameShort: '',
        email: '',
        tel: '',
        fax: '',
        cc: '',
        title: '',
      }
  ]

  public onChange(entry: any) {
    this.selectedUser = entry
  }

  public angebotsNr = '14141414 BG-S2';

  public ihreAnfrage = '<xx> Jahres Wartung an <x> Stück <xxx>, Bestandsaufnahme in <xxx>';

  public anrede = 'bezugnehmend auf die oben genannte Anfrage vom xx. Februar 2023, bieten wir Ihnen nachfolgend Teile sowie Dienstleistung an.';

  public lieferzeitMaterial = [
    '14 Wochen, nach schriftlicher Bestellung...',
    'Lieferfrist nach technischer Klaerung...'
  ];

  public lieferzeitMontage = [
    '14 Wochen, nach schriftlicher Bestellung...',
    'Lieferfrist nach technischer Klaerung...'
  ];

  public zahlungsbedingungen = [
    'Nach Lieferung, 14 Tage netto nach Rechnungsdatum',
    '20% vorkasse',
    '30% vorkasse',
    '30% vorkasse, Rest nach 30 Tagen',
    '50% vorkasse',
    '100% vorkasse',
    '30% Anzahlung, 70% vor Lieferung',
    'nach Ruecksprache',
  ];

  public lieferbedingungen = ['Ab Werk, verpackt auf Palette und verladen'];

  public gewaehrleistungen = [
    '18/24',
    '18/24 + 5 jahre',
    '24/36',
    '24/36 + 5 jahre',
    '36/48',
    '36/48 + 5 jahre',
  ];

  public technischeLieferbedingungen = [
    'PED 2014/68/EU, ATEX 2014/34/EU...',
    'nach Kundenspezifikation'
  ];

  public dokumentationen = [
    'Bedienungs- und Wartungsanleitung mit Zeichnungen, Datenblättern, Stücklisten, ATEX Baumusterprüfbescheinigungen für elektrische Komponenten, 1x digital in englischer Sprache',
  ];
  //  für elektrische Komponenten, 1x digital in englischer Sprache

  public angebotsgueltigkeit = [
    'Unser Angebot hat eine Gültigkeit von 3 Monaten.'
  ];


  public bemerkungMaterial = 'Abrechnung erfolgt hinterher nach tatsächlich eingesetztem Material.'
  public bemerkungMontage = 'Der Aufwand dieser Wartung wirdgemäß beiliegender Montage -und Servicebedingungen nach tatsächlichem Aufwand abgerechnet.'
  public bemerkungDokumentation = ''


  private updateMontage(value: any) {
    this.offer.montage = value;
  }

  private materialUpdated(value: any) {
    this.offer.material = value;
  }

  private showConfirmDialog: boolean = false;

  public close() {
    this.confirmCloseOfferView();
  }

  confirmCloseOfferView() {
    this.showConfirmDialog = true;
  }

  exitOfferView() {
    this.showConfirmDialog = false;
    this.$emit('closeDialog', false);
  }

  cancelConfirmDialog() {
    this.showConfirmDialog = false;
  }

  public async generateOffer(type: string) {

      const editedAddress = {
        customerName: this.customerName_form,
        street: this.street_form,
        houseNo: this.houseNo_form,
        zipCode: this.zipCode_form,
        city: this.city_form,
        country: this.country_form,
        contactPerson: this.contactPerson_form,
        mail: this.mail_form,
      }

      if(type === 'word'){
        await WordService().generateOffer(this.station, this.customer, this.offer, editedAddress, this.selectedUser);
      } else {
        await PdfService().generateOffer(this.station, this.customer, this.offer, editedAddress, this.selectedUser )
      }
  }

}
